import React from 'react';

import IntlMessages from '../helpers/IntlMessages';
import { adminRoot } from './defaultValues';

const data = [
  // {
  //   id: 'dashboard',
  //   icon: 'indicatorsIcon',
  //   label: <IntlMessages id='screens.dashboard' />,
  //   to: `${adminRoot}/dashboard`,
  // },
  // {
  //   id: 'financial',
  //   icon: 'financialIcon',
  //   label: <IntlMessages id='screens.financial' />,
  //   to: `${adminRoot}/financial-report`,
  // },
  // {
  //   id: 'settings',
  //   icon: 'configurationsIcon',
  //   label: <IntlMessages id='screens.config' />,
  //   sub_title: <IntlMessages id='screens.generalConfig' />,
  //   to: `${adminRoot}/settings`,
  //   subs: [
  //     {
  //       icon: 'iconsminds-forward',
  //       label: 'screens.config.termsOfUse',
  //       to: `${adminRoot}/settings/terms-of-use`,
  //     },
  //   ],
  // },
  {
    id: 'records',
    icon: 'recordsIcon',
    label: <IntlMessages id='screens.register' />,
    sub_title: <IntlMessages id='screens.register' />,
    to: `${adminRoot}/records`,
    subs: [
      // {
      //   icon: 'iconsminds-forward',
      //   label: 'screens.register.technicalTipsCategories',
      //   to: `${adminRoot}/records/technical-tips-categories`,
      // },
      // {
      //   icon: 'iconsminds-forward',
      //   label: 'screens.register.technicalTips',
      //   to: `${adminRoot}/records/technical-tips`,
      // },
      {
        icon: 'iconsminds-forward',
        label: 'screens.register.productLines',
        to: `${adminRoot}/records/product-lines`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.register.products',
        to: `${adminRoot}/records/products`,
      },
      {
        icon: 'iconsminds-forward',
        label: 'screens.register.brands',
        to: `${adminRoot}/records/brands`,
      },
      // {
      //   icon: 'iconsminds-forward',
      //   label: 'screens.register.banner',
      //   to: `${adminRoot}/records/banners`,
      // },
      // {
      //   icon: 'iconsminds-forward',
      //   label: 'screens.register.video',
      //   to: `${adminRoot}/records/videos`,
      // },
      {
        icon: 'iconsminds-forward',
        label: 'screens.register.companyInformation',
        to: `${adminRoot}/records/company-information`,
      },
      // {
      //   icon: 'iconsminds-forward',
      //   label: 'screens.register.staticImages',
      //   to: `${adminRoot}/records/static-images`,
      // },
    ],
  },
  {
    id: 'reports',
    icon: 'reportsIcon',
    label: <IntlMessages id='screens.report' />,
    sub_title: <IntlMessages id='screens.report' />,
    to: `${adminRoot}/reports`,
    subs: [
      {
        icon: 'iconsminds-forward',
        label: 'screens.report.analytics',
        to: `${adminRoot}/reports/analytics`,
      },
    ],
  },
];

export default data;
